<template>
<div>
    <template>
        <div v-if="!collections || collections.length == 0">
            <div class="text-muted">{{ translate('no_results') }}</div>
        </div>
        <div v-else>
            <div class="text-muted">{{ translate('results_found') }}: {{ collections.length }}</div>
        </div>
        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
            <template v-slot:append>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddEercisesCollectionsPanel" flat dense color="dark" no-caps :label="add_exercises_collections_title" icon="add" />
            </template>
        </q-input>
        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + item_id">
            <q-item class="list-item full-width p-0 pr-2 pt-2 pb-2" :class="i%2===0 ? 'bg-custom-lightgrey' : ''" ripple v-for="(collection,i) in collections" :key="'list-item-' + i + '-' + collection.id">
                <q-item-section class="p-0">
                    <div class="row m-0 align-items-center">
                        <div v-if="$q.screen.lt.sm" class="col-12 p-0 pl-2 text-blue-10">
                            <strong>{{ collection.name }}</strong>
                        </div>
                        <div class="col-md-4 p-0">
                            <div>
                                <q-radio style="width: 50%" v-model="collection.timing_type" @input="setCollectionTimingType('rounds', collection, 'time')" :label="translate('rounds')" :val="'rounds'" />
                                <q-radio style="width: 50%" v-model="collection.timing_type" @input="setCollectionTimingType('time', collection, 'rounds')" :label="translate('time')" :val="'time'" />
                            </div>
                            <q-input v-if="collection.timing_type === 'rounds'" :stack-label="$q.screen.lt.sm ? true : null" outlined dense square v-model.number="collection.rounds" :label="translate('rounds')"></q-input>
                            <q-input v-if="collection.timing_type === 'time'" :label="translate('time')" :stack-label="$q.screen.lt.sm ? true : null" dense outlined sqaure no-error-icon v-model="collection.time" mask="fulltime">
                                <template v-slot:append>
                                    <q-icon name="access_time" class="cursor-pointer">
                                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                                        <q-time v-model="collection.time" with-seconds format24h>
                                            <div class="row items-center justify-center full-width">
                                                <q-btn v-close-popup :label="translate('close')" class="full-width" color="primary" dense flat no-caps />
                                            </div>
                                        </q-time>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </div>
                        <div v-if="!$q.screen.lt.sm" class="col-8 p-0 text-blue-10 text-center">
                            <strong>{{ collection.name }}</strong>
                        </div>
                    </div>
                </q-item-section>
                <q-item-section side class="p-0">
                    <q-btn icon="add" @click.prevent="registerItem(collection, collection.id)" dense flat color="primary" />
                </q-item-section>
            </q-item>
        </div>
        <infinite-loading @infinite="getExercisesCollections" ref="infiniteLoading" :identifier="'included-exercises-collections-' + entity_type + '-' + item_id + '-' +infExercisesCollections" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import { min } from 'moment'
import AddItem from './AddItem'

export default {
    name: 'IncludeTrainingExercisesCollections',
    props: ['post_route', 'get_route_to_include', 'get_route', 'item_id', 'placeholder_find_item', 'entity_type'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading
    },
    data: function () {
        return {
            collections: [],
            page: 1,
            state: {},
            infExercisesCollections: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null,
                sets: 0,
                reps: 0,
                value_per_rep: 0
            }
        }
    },
    computed: {
        add_exercises_collections_title: function () {
            return this.translate('new_exercises_collection')
        }
    },
    mounted: function () {
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
    },
    methods: {
        showAddEercisesCollectionsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_exercises_collections_title,
                    post_route: baseUrl + 'exercises_collections',
                    entity_type: this.entity_type,
                }
            })
        },
        setCollectionTimingType: function (type, collection, nullable) {
            collection.timing_type = type
            collection[nullable] = null
        },
        resetMainList: function () {
            this.page = 1
            this.collections = []
            this.infExercisesCollections++
        },
        post_data: function (item, collection_id) {
            var data = {
                "training_id": this.item_id,
                "exercises_collection_id": collection_id
            }
            if (item.rounds && item.timing_type === 'rounds') {
                data['rounds'] = item.rounds
            }
            if (item.timing_type && item.timing_type === 'time') {
                var seconds = 0,
                    hoursSeconds = parseInt(item.time.slice(0,2)) * 60 * 60,
                    minutesSeconds = parseInt(item.time.slice(3,5)) * 60,
                    secondsSeconds = parseInt(item.time.slice(6,8))
                
                seconds = hoursSeconds + minutesSeconds + secondsSeconds
                
                data['seconds'] = seconds
            }
            
            return data
        },
        getExercisesCollections: function ($state) {
            var route = baseUrl + 'exercises_collections'
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&search=' + this.searchItem
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.collections.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerItem: function (item, collection_id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var data = this.post_data(item, collection_id)
            
            var url = this.post_route + '/link'
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        this.$emit('update_list')
                        eventBus.$emit('update_included_items', {id: this.item_id, route: this.get_route_to_include})
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                    var msg = error.response.data.msg
                    this.$toasted.error(msg, {
                        duration: 3000
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 50vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
body .search-input {
    width: calc(100% + 30px);
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.draggable-item:hover {
    border-style: dotted;
    border-color: rgba(0, 0, 0, .85);
}
</style>
