<template>
<div class="animated fadeIn fit">
    <div v-if="$q.screen.lt.sm" class="text-right">
        <q-btn :icon="show_tabs ? 'close' : 'menu'" @click.prevent="show_tabs=!show_tabs" flat :color="show_tabs ? 'dark' : 'primary'" />
    </div>
    <q-tabs :class="mobile_tabs" clickable hoverable no-caps v-model="tab" class="mb-1 personal-file-tabs" align="left">
        <q-tab class="custom-tab text-positive" name="exercises">
            <div>
                <div class="tab-text">
                    <i class="fas fa-dumbbell text-positive mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('exercises') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-indigo" name="exercises_collections">
            <div>
                <div class="tab-text">
                    <i class="fas fa-dumbbell text-indigo mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('exercises_collections') }}</strong>
                </div>
            </div>
        </q-tab>
        <q-tab class="custom-tab text-accent" name="trainings">
            <div>
                <div class="tab-text">
                    <i class="fas fa-dumbbell text-accent mr-2 fa-lg"></i> <strong class="text-dark">{{ translate('trainings') }}</strong>
                </div>
            </div>
        </q-tab>
    </q-tabs>
    <div class="mb-4">
        <template v-if="is_exercises">
            <exercises />
        </template>
        <template v-if="is_exercises_collections">
            <exercises-collections />
        </template>
        <template v-if="is_trainings">
            <trainings />
        </template>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../../../main'
import Exercises from './tab-panels/Exercises'
import ExercisesCollections from './tab-panels/ExercisesCollections'
import Trainings from './tab-panels/Trainings'

export default {
    name: 'ExercisesAndTrainings',
    components: {
        'exercises': Exercises,
        'exercises-collections': ExercisesCollections,
        'trainings': Trainings,
    },
    computed: {
        is_exercises: function () {
            return this.tab === 'exercises'
        },
        is_exercises_collections: function () {
            return this.tab === 'exercises_collections'
        },
        is_trainings: function () {
            return this.tab === 'trainings'
        },
        mobile_tabs: function () {
            if (this.$q.screen.lt.sm) {
                return !this.show_tabs ? 'hidden' : 'mobile-tabs bg-white'
            }
            return ''
        },
    },
    data: function () {
        return {
            show_tabs: this.$q.screen.lt.sm ? false : true,
            tab: 'exercises',
        }
    },
    created: function () {
        eventBus.$on('hideTabs', () => {
            this.hideTabs()
        })
    },
    methods: {
        hideTabs: function () {
            this.show_tabs = false
        },
    },
}
</script>

<style lang="scss" scoped>
body .custom-tab {
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
    margin: 0 .25rem 0 0;
    padding: 0;
    background: #fff;

    &:hover {
        background: inherit;
    }
    div {
        display: block;
    }
    .image-holder {
        // width: 300px;
        width: 215px;
        display: block;
        height: 90px;
        margin: -4px 0 0.25rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .tab-text {
        // max-width: 185px;
        max-width: 100%;
        padding: 2px 15px 4px;
    }
}

@media screen and (max-width: 320px) {
    body .custom-tab {
        .image-holder {
            width: 200px;
        }
        .tab-text {
            max-width: 170px;
        }
    }
}
.align-self-start {
    display: inline!important;
}
.bg-transparent {
    background-color: transparent;
}
</style>
