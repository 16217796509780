<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteItemPanel(item_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="item_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ edit_item_title }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="list-group-item-accent-info p-0 pt-2 pb-2">
                        <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="item.name" :placeholder="translate('enter_name')" />
                        <q-input class="m-0" :label="translate('description')" :placeholder="translate('enter_description')" v-model="item.description" square dense color="primary" type="textarea" rows="2" />
                    </b-list-group-item>
                    <template v-if="include_single_choice && include_single_choice.length">
                        <div v-for="(choice, i) in include_single_choice" :key="'list-choice-field-' + i">
                            <q-separator />
                            <b-list-group-item class="list-group-item-accent-info bg-light pt-2 pb-2 font-weight-bold">
                                {{ translate(choice.field) }}
                            </b-list-group-item>
                            <b-list-group-item class="list-group-item-accent-info pt-2 pb-2">
                                <include-single-choice :item_id="item_id" :initial_id="include_single_choice[i][choice.field + '_id']" :initial_name="include_single_choice[i][choice.field + '_name']" :placeholder_find_item="'find_' + choice.field" :entity_type="entity_type" :field_name="choice.field" :get_route_to_include="choice.get_route_to_include" />
                            </b-list-group-item>
                        </div>
                    </template>
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item @click="showIncludeItemPanel(item_id, include_entity_type)" v-ripple clickable dark class="bg-blue-6 align-items-center full-width pr-3 justify-items-spacebetween">
                            <q-item-label>
                                <strong>{{ translate('include_' + include_entity_type) }}</strong>
                            </q-item-label>
                            <q-space class="pr-2" />
                            <q-item-section side class="pr-0">
                                <q-btn flat round class="shadow-2 m-0 p-1">
                                    <i class="fas fa-2x fa-running text-white"></i>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editItem">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import DeleteItem from './DeleteItem'
import IncludeItem from '../../template-parts/forms/AddIncludedCollectionItems'
import IncludeExercises from '../../template-parts/forms/AddIncludedExercisesCollectionExercises'
import IncludeExercisesCollections from '../../template-parts/forms/AddIncludedTrainingExercisesCollections'
import ToggleActive from '../../../template-parts/ToggleActive'
import IncludeSingleChoice from './IncludeSingleChoice'

export default {
    name: 'EditItem',
    props: [
        'item_id', 'is_active', 'entity_type', 'include_single_choice', 
        'training_phase_id', 'training_phase_name', 'training_level_id', 'training_level_name', 'training_type_id', 'training_type_name', 
        'patch_toggle_route', 'get_route_to_include', 'link_post_route', 'include_entity_type', 'get_route', 'post_route', 
        'delete_item_title', 'deleting_item_message', 'edit_item_title', 'field_unit', 'field_description'],
    components: {
        'toggle-active': ToggleActive,
        'include-single-choice': IncludeSingleChoice
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            item: {
                name: null,
                description: ''
            },
            selected_training_phase_id: this.training_phase_id,
            selected_training_phase_name: this.training_phase_name,
            selected_training_level_id: this.training_level_id,
            selected_training_level_name: this.training_level_name,
            selected_training_type_id: this.training_type_id,
            selected_training_type_name: this.training_type_name,
        }
    },
    created: function () {
        this.getItem()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        if (this.include_single_choice && this.include_single_choice.length) {
            this.include_single_choice.map(choice => {
                eventBus.$on('update_selected_single_choice_id_' + this.entity_type + '_'+ choice.field, (item) => {                    
                    this.updateSelectedTrainingClassificationId(item, choice.field)
                })
            })
        }
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        updateSelectedTrainingClassificationId: function (item, choice_field) {
            this['selected_' + choice_field + '_id'] = item.id
            this['selected_' + choice_field + '_name'] = item.name
        },
        showIncludeItemPanel: function (id, entity_type) {
            const componentName = this.entity_type === 'exercises_collection' ? IncludeExercises : 
                (this.entity_type === 'training' ? IncludeExercisesCollections : IncludeItem)
            const panelInstance = this.$showPanel({
                component: componentName,
                props: {
                    item_id: id,
                    get_route: this.get_route,
                    post_route: this.link_post_route,
                    get_route_to_include: this.get_route_to_include,
                    entity_type: entity_type,
                    main_title: entity_type + 's',
                    placeholder_find_item: 'find_' + entity_type,
                }
            })
        },
        showDeleteItemPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    delete_item_title: this.delete_item_title,
                    deleting_item_message: this.deleting_item_message,
                    post_route: this.post_route,
                    entity_type: this.entity_type
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getItem: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var entity_type = this.entity_type

            axios.get(this.get_route + '/' + this.item_id, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.item) {
                        this.item = response.data.item
                        this.item.name = response.data.item.name
                    } else {
                        this.item = response.data
                        this.item.name = response.data.name    
                    }
                    
                    if (this.include_single_choice && this.include_single_choice.length) {
                        this.include_single_choice.map(choice => {
                            eventBus.$emit('update_selected_single_choice_' + this.entity_type + '_'+ choice.field, {
                                id: this[choice.field +  '_id'] ? this[choice.field + '_id'] : null,
                            })
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editItem: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }
            var item_id = this.item_id
            var item_name = this.item.name
            var item_description = this.item.description
            var data = {
                'name': item_name,
                'description': item_description,
            }

            if (this.include_single_choice && this.include_single_choice.length) {
                this.include_single_choice.map(choice => {
                    if (this['selected_' + choice.field + '_id']) {
                        data[choice.field +  '_id'] = this['selected_' + choice.field + '_id']
                    }
                })
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            axios.put(this.post_route + '/' + this.item_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_' + this.entity_type + '_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        const emitData = {
                            status: response.data.status,
                            id: item_id,
                            name: item_name,
                        }
                        if (this.include_single_choice && this.include_single_choice.length) {
                            this.include_single_choice.map(choice => {
                                if (this['selected_' + choice.field + '_id']) {
                                    emitData[choice.field +  '_name'] = this['selected_' + choice.field + '_name']
                                    emitData[choice.field +  '_id'] = this['selected_' + choice.field + '_id']
                                }
                            })
                        }
                        this.$emit('closePanel', emitData)
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            })
        }
    }
}
</script>
