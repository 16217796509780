<template>
<collection-library-item 
    :class="container_class" 
    :entity_type="entity_type" 
    :field_exercises_collections="field_exercises_collections"
    :link_post_route="link_post_route" 
    :get_route_to_include="get_route_to_include" 
    :include_entity_type="include_entity_type" 
    :get_route="get_route" 
    :post_route="post_route" 
    :patch_toggle_route="patch_toggle_route"
    :main_title="main_title" 
    :add_button_text="add_button_text" 
    :add_item_title="add_item_title" 
    :edit_item_title="edit_item_title" 
    :delete_item_title="delete_item_title" 
    :deleting_item_message="deleting_item_message" 
    :include_single_choice="include_single_choice" 
    :placeholder_find_item="placeholder_find_item" >
    <template v-slot:buttons="props">
        <q-btn round :flat="props.flat" icon="fas fa-running" color="accent" @click="showIncludeExercisesCollectionPanel(props.item_id, include_entity_type)">
            <q-tooltip :offset="[10, 10]">{{ translate('include_' + include_entity_type) }}</q-tooltip>
        </q-btn>
    </template>
</collection-library-item>
</template>

<script>
import CollectionLibraryItem from '../../template-parts/CollectionLibraryItem'
import IncludeExercisesCollection from '../../template-parts/forms/AddIncludedTrainingExercisesCollections'

export default {
    name: 'Trainings',
    components: {
        'collection-library-item': CollectionLibraryItem
    },
    computed: {
        field_exercises_collections: function () {
            return true
        },
        include_single_choice: function () {
            return [{
                field: this.field_training_level,
                get_route_to_include: this.get_route_training_levels
            },
            {
                field: this.field_training_type,
                get_route_to_include: this.get_route_training_types
            }]
        },
        field_training_level: function () {
            return 'training_level'
        },
        get_route_training_levels: function () {
            return baseUrl + 'training_levels'
        },
        field_training_type: function () {
            return 'training_type'
        },
        get_route_training_types: function () {
            return baseUrl + 'training_types'
        },
        container_class: function () {
            return 'trainings'
        },
        entity_type: function () {
            return 'training'
        },
        get_route: function () {
            return baseUrl + 'trainings'
        },
        include_entity_type: function () {
            return 'exercises_collections'
        },
        get_route_to_include: function () {
            return baseUrl + 'exercises_collections'
        },
        post_route: function () {
            return baseUrl + 'trainings'
        },
        patch_toggle_route: function () {
            return baseUrl + 'trainings'
        },
        link_post_route: function () {
            return baseUrl + 'trainings'
        },
        main_title: function () {
            return this.translate('trainings')
        },
        add_button_text: function () {
            return this.translate('add_training')
        },
        add_item_title: function () {
            return this.translate('add_training')
        },
        edit_item_title: function () {
            return this.translate('edit_trainings')
        },
        delete_item_title: function () {
            return this.translate('delete_training')
        },
        deleting_item_message: function () {
            return this.translate('deleting_training')
        },
        placeholder_find_item: function () {
            return this.translate('find_training')
        },
    },
    data: function () {
        return {
        }
    },
    methods: {
        showIncludeExercisesCollectionPanel: function (id, entity_type) {
            const panelInstance = this.$showPanel({
                component: IncludeExercisesCollection,
                props: {
                    item_id: id,
                    get_route: this.get_route,
                    post_route: this.link_post_route,
                    get_route_to_include: this.get_route_to_include,
                    entity_type: entity_type,
                    main_title: entity_type,
                    placeholder_find_item: 'find_' + entity_type,
                }
            })
        },
    },
}
</script>
