<template>
<div>
    <div class="card-header bg-white pl-3 pr-3 pt-2 pb-0">
        <q-item class="full-width m-0 p-0">
            <q-item-section side class="p-0">
                <h3 class="text-dark text-h6">{{ main_title }}</h3>
            </q-item-section>
        </q-item>
    </div>

    <q-table locale="ro-RO" class="my-sticky-header-column-table mb-3" ref="datatable" :title="translate('exercises')" :data="data" :columns="columns" :pagination.sync="pagination" :separator="separator" :loading="loading" row-key="name" :expanded.sync="expanded" @request="onRequest" no-data-label="I didn't find anything for you" no-results-label="The filter didn't uncover any results">
        <template v-slot:top class="p-0">
            <search-wrapper-card card_classes="full-width p-0" :fullwidth_textinput="true" :active="exercise_active" :activeStatus="activeStatus" :inactive="exercise_inactive" :inactiveStatus="inactiveStatus" :form_type="'items-' + entity_type">
                <q-input @input="isTyping = true" slot="search_input" bottom-slots v-model="filter" :id="'filtername-item-' + entity_type" class="search-input" type="text" :placeholder="placeholder_find_item" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <template slot="add_button">
                    <q-item class="p-0">
                        <q-item-section side class="pr-2">
                            <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddItemPanel">
                                <i class="fa fa-plus mr-1"></i> {{ add_button_text }}
                            </q-btn>
                            <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddItemPanel" />
                        </q-item-section>
                        <q-item-section side class="pr-2">
                            <import-csv :import_csv_post_url="import_csv_post_url" :panel_title="translate(entity_type + 's')" :entity_type="entity_type" />
                        </q-item-section>
                    </q-item>
                </template>
            </search-wrapper-card>
        </template>
        <template v-slot:header="props">
            <q-tr :props="props">
                <q-th auto-width />
                <q-th v-for="col in props.cols" :key="col.name" :props="props" valign="bottom">
                    <span class="font-weight-normal text-muted" v-if="col.caption">{{ col.caption }}<br /></span>
                    {{ col.label }}
                </q-th>
            </q-tr>
        </template>
        <template v-slot:body="props">
            <q-tr :props="props" :class="props.row.name === expanded[0] ? 'expanded' : ''">
                <q-td auto-width>
                    <q-btn size="sm" color="accent" round dense @click="toggleExpanded(props.row.name)" :icon="props.row.name === expanded[0] ? 'fa fa-angle-up' : 'fa fa-angle-down'" />
                </q-td>
                <q-td v-for="col in props.cols" :key="col.name" :props="props">
                    {{ col.value }}
                    <q-popup-edit auto-save v-if="col.name === 'name'" v-model="props.row.name" buttons @save="updateExerciseName(props.row.id, props.row.name)">
                        <q-input v-model="props.row.name" dense autofocus counter />
                    </q-popup-edit>
                </q-td>
            </q-tr>
            <q-tr v-show="props.row.name === expanded[0]" :props="props" :class="props.row.name === expanded[0] ? 'expanded' : ''">
                <q-td colspan="100%">
                    <div class="text-left">
                        <q-item class="m-0 p-0">
                            <q-item-section side clickable class="align-items-center" @click="showEditItemPanel(props.row.id, props.row.is_active, props.row.training_category_id, props.row.training_category_name)">
                                <q-btn class="pt-3 pb-3" stack rounded flat no-caps size="md" icon="edit" color="primary">
                                    <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                                    <strong class="text-muted">{{ translate('edit_' + entity_type) }}</strong>
                                </q-btn>
                            </q-item-section>
                            <q-item-section side clickable class="align-items-center" @click="showIncludeItemPanel(props.row.id, 'muscle')">
                                <q-btn class="pt-3 pb-3" stack rounded flat no-caps size="md" icon="fas fa-bone" color="blue-5">
                                    <q-tooltip :offset="[10, 10]">{{ translate('include_muscle') }}</q-tooltip>
                                    <strong class="text-muted">{{ translate('muscles') }}</strong>
                                </q-btn>
                            </q-item-section>
                            <q-item-section side clickable class="align-items-center" @click="showIncludeItemPanel(props.row.id, 'equipment')">
                                <q-btn class="pt-3 pb-3" stack rounded flat no-caps size="md" icon="fas fa-chair" color="green-5">
                                    <q-tooltip :offset="[10, 10]">{{ translate('include_equipment') }}</q-tooltip>
                                    <strong class="text-muted">{{ translate('equipments') }}</strong>
                                </q-btn>
                            </q-item-section>
                            <q-item-section side clickable class="align-items-center" @click="showIncludeItemPanel(props.row.id, 'medical_problem', 'field_reference')">
                                <q-btn class="pt-3 pb-3" stack rounded flat no-caps size="md" icon="fas fa-heart-broken" color="purple-5">
                                    <q-tooltip :offset="[10, 10]">{{ translate('include_medical_problem') }}</q-tooltip>
                                    <strong class="text-muted">{{ translate('medical_problems') }}</strong>
                                </q-btn>
                            </q-item-section>
                            <q-item-section side clickable class="align-items-center" @click="showDeleteItemPanel(props.row.id)">
                                <q-btn class="pt-3 pb-3" stack rounded flat no-caps size="md" icon="delete" color="danger">
                                    <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                                    <strong class="text-muted">{{ translate('delete_' + entity_type) }}</strong>
                                </q-btn>
                            </q-item-section>
                            <q-item-section v-if="patch_toggle_route" side class="align-items-center">
                                <toggle-active :stacked="true" :is_active="props.row.is_active" :patch_toggle_route="patch_toggle_route" :item_id="props.row.id" :entity_type="entity_type" />
                            </q-item-section>
                        </q-item>
                    </div>
                </q-td>
            </q-tr>
        </template>
        <template v-slot:no-data="{ icon, message, filter }">
            <!-- <div class="full-width row flex-center text-accent q-gutter-sm">
                <q-icon size="2em" name="sentiment_dissatisfied" />
                <span>
                    Well this is sad... {{ message }}
                </span>
                <q-icon size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div> -->
        </template>
    </q-table>
    <q-pagination color="primary" @click.stop.prevent="getItems()" v-model="pagination.page" :max="pages_number" size="sm" />
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from "../../../../main"
import IncludeItem from '../../template-parts/forms/AddIncludedItems'
import AddItem from '../../template-parts/forms/AddItem'
import EditItem from '../../template-parts/forms/EditItem'
import DeleteItem from '../../template-parts/forms/DeleteItem'
import SearchWrapperCard from '../../../template-parts/SearchWrapperCard'
import ToggleActive from '../../../template-parts/ToggleActive'
import ImportCSV from '../../import/ImportCSV'

export default {
    name: 'Exercises',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        'import-csv': ImportCSV,
    },
    created: function () {
        eventBus.$on('update_all', () => {
            this.getItems()
        })
        eventBus.$on('update_included_items', (item) => {
            if (item) {
                this.getItems()
            }
        })
    },
    computed: {
        include_single_choice: function () {
            return [{
                field: this.field_training_category,
                get_route_to_include: this.get_route_training_categories
            }]
        },
        pages_number: function () {
            return Math.ceil(this.total_items / this.pagination.rowsPerPage)
        },
        field_training_category: function () {
            return 'training_category'
        },
        get_route_training_categories: function () {
            return baseUrl + 'training_categories'
        },
        container_class: function () {
            return 'exercises'
        },
        entity_type: function () {
            return 'exercise'
        },
        get_route: function () {
            return baseUrl + 'exercises'
        },
        import_csv_post_url: function () {
            return this.get_route + '/import'
        },
        post_route: function () {
            return baseUrl + 'exercises'
        },
        patch_toggle_route: function () {
            return baseUrl + 'exercises'
        },
        link_post_route: function () {
            return baseUrl + 'exercises/link'
        },
        main_title: function () {
            return this.translate('exercises')
        },
        add_button_text: function () {
            return this.translate('add_exercise')
        },
        add_item_title: function () {
            return this.translate('add_exercise')
        },
        edit_item_title: function () {
            return this.translate('edit_exercise')
        },
        delete_item_title: function () {
            return this.translate('delete_exercise')
        },
        deleting_item_message: function () {
            return this.translate('deleting_exercise')
        },
        placeholder_find_item: function () {
            return this.translate('find_exercise')
        },
        columns: function () {
            return [{
                    name: 'name',
                    required: true,
                    caption: this.translate('names'),
                    label: this.translate('exercises'),
                    align: 'left',
                    field: row => row.name,
                    format: val => `${val}`,
                    sortable: true
                },
                {
                    name: 'unit_name',
                    required: true,
                    caption: this.translate('names'),
                    label: this.translate('unit'),
                    align: 'left',
                    field: 'unit_name',
                    sortable: true
                },
                {
                    name: 'training_category_name',
                    align: 'left',
                    caption: this.translate('names'),
                    label: this.translate('training_category'),
                    field: 'training_category_name',
                    sortable: true
                },
                {
                    name: 'muscles',
                    align: 'left',
                    caption: this.translate('names'),
                    label: this.translate('muscles'),
                    field: 'muscles',
                    sortable: true
                },
                {
                    name: 'equipments',
                    align: 'left',
                    caption: this.translate('names'),
                    label: this.translate('equipments'),
                    field: 'equipments',
                    sortable: true
                },
                {
                    name: 'medical_problems_recommended',
                    align: 'left',
                    caption: this.translate('medical_problems'),
                    label: this.translate('recommended'),
                    field: 'medical_problems_recommended',
                    sortable: true
                },
                {
                    name: 'medical_problems_not_recommended',
                    align: 'left',
                    caption: this.translate('medical_problems'),
                    label: this.translate('not_recommended'),
                    field: 'medical_problems_not_recommended',
                    sortable: true
                },
                {
                    name: 'medical_problems_forbidden',
                    align: 'left',
                    caption: this.translate('medical_problems'),
                    label: this.translate('forbidden'),
                    field: 'medical_problems_forbidden',
                    sortable: true
                },
            ]
        },
    },
    data: function () {
        return {
            expanded: [],
            loading: false,
            baseUrl: baseUrl,
            data: [],
            total_items: 0,
            filter: '',
            isTyping: false,
            separator: 'cell',
            pagination: {
                sortBy: 'name',
                descending: false,
                page: 1,
                rowsPerPage: this.$items_per_page,
                rowsNumber: this.total_items
            },
            exercise_active: 'active',
            exercise_inactive: '',
        }
    },
    mounted() {
        // get initial data from server (1st page)
        this.onRequest({
            pagination: this.pagination,
            filter: undefined
        })
    },
    watch: {
        filter: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    methods: {
        activeStatus: _.debounce(function () {
            if (this.exercise_active === 'active') {
                this.exercise_active = ''
            } else {
                this.exercise_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.exercise_inactive === 'inactive') {
                this.exercise_inactive = ''
            } else {
                this.exercise_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        updateExerciseName: function (id, name) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var data = {
                'name': name,
            }

            axios.put(this.post_route + '/' + id, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var message = 'edit_' + this.entity_type + '_' + this.msg
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status,
                            name: name
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        toggleExpanded: function (val, props) {
            this.expanded = this.expanded[0] === val ? [] : [val]
        },
        resetMainList: function () {
            this.pagination = {
                sortBy: 'name',
                descending: false,
                page: 1,
                rowsPerPage: this.$items_per_page,
                rowsNumber: this.total_items
            },
            this.data = []
            this.getItems()
        },
        onRequest(props) {
            const {
                page,
                rowsPerPage,
                sortBy,
                descending
            } = props.pagination
            const filter = props.filter

            this.loading = true

            // update rowsCount with appropriate value
            this.getRowsNumberCount()

            // emulate server
            this.emulateServer(rowsPerPage, page, filter, sortBy, descending)
        },
        emulateServer: _.debounce(function (rowsPerPage, page, filter, sortBy, descending) {
            this.pagination.rowsNumber = this.total_items

            // get all rows if "All" (0) is selected
            const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage

            // don't forget to update local pagination object
            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            // calculate starting row of data
            const startRow = (this.pagination.page - 1) * rowsPerPage

            // fetch data from "server"
            const returnedData = this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending)

            // clear out existing data and add new
            this.data.splice(0, this.total_items, ...returnedData)

            // ...and turn of loading indicator
            this.loading = false
        }, 500),
        showAddItemPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.post_route,
                    entity_type: this.entity_type,
                    include_single_choice: this.include_single_choice,
                    field_unit: true,
                    field_unit_title: 'unit',
                    field_muscle: true,
                    field_muscle_title: 'muscles',
                    field_equipment: true,
                    field_equipment_title: 'equipment',
                    field_medical_problem: true,
                    field_medical_problem_title: 'medical_problems',
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditItemPanel(id, is_active, training_category_id, training_category_name) {
            const panelInstance = this.$showPanel({
                component: EditItem,
                props: {
                    item_id: id,
                    edit_item_title: this.edit_item_title,
                    get_route: this.get_route,
                    post_route: this.post_route,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active,
                    include_single_choice: this.include_single_choice,
                    training_category_id: training_category_id ? training_category_id : 0,
                    training_category_name: training_category_name ? training_category_name : '',
                    field_unit: true
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        // Find index of specific object using findIndex method,
                        var objIndex = this.data.findIndex((obj => obj.id === id))
                        // and update the name.
                        this.data[objIndex].name = result.name
                        this.data[objIndex].training_category_name = result.training_category_name
                        this.data[objIndex].training_category_id = result.training_category_id
                    }
                })
        },
        showDeleteItemPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    delete_item_title: this.delete_item_title,
                    deleting_item_message: this.deleting_item_message,
                    post_route: this.post_route,
                    entity_type: this.entity_type
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        // Find index of specific object using findIndex method,
                        var objIndex = this.data.findIndex((obj => obj.id === id))
                        // and remove the element from the data array.
                        this.data.splice(objIndex, 1)
                    }
                })
        },
        showIncludeItemPanel: function (id, entity_type, field_reference) {
            const panelInstance = this.$showPanel({
                component: IncludeItem,
                props: {
                    item_id: id,
                    get_route: this.get_route,
                    post_route: this.link_post_route,
                    entity_type: entity_type,
                    main_title: entity_type + 's',
                    placeholder_find_item: 'find_' + entity_type,
                    field_reference: field_reference,
                }
            })
        },
        getItems: function () {
            var route = this.get_route + '/table'
            var url = route + '?page=' + this.pagination.page + '&items_per_page=' + this.pagination.rowsPerPage
            if (this.filter) {
                url += '&search=' + this.filter
            }
            if (this.exercise_active === 'active' && this.exercise_inactive === '') {
                url += '&status=' + this.exercise_active
            }
            if (this.exercise_inactive === 'inactive' && this.exercise_active === '') {
                url += '&status=' + this.exercise_inactive
            }
            url += '&order_by=' + this.pagination.sortBy
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        this.data = []
                        this.data = response.data.items.map(entity => {
                            let array = {
                                id: entity.id,
                                is_active: entity.is_active,
                                name: entity.name,
                                unit_name: entity.unit_name ? entity.unit_name : '',
                                training_category_name: entity.training_category_name ? entity.training_category_name : '',
                                training_category_id: entity.training_category_id ? entity.training_category_id : 0,
                                muscles: entity.muscles ? entity.muscles['default'].join(', ') : '',
                                equipments: entity.equipments ? entity.equipments['default'].join(', ') : '',
                                medical_problems_recommended: entity.medical_problems && entity.medical_problems['recommended'] ? entity.medical_problems['recommended'].join(', ') : '',
                                medical_problems_not_recommended: entity.medical_problems && entity.medical_problems['not_recommended'] ? entity.medical_problems['not_recommended'].join(', ') : '',
                                medical_problems_forbidden: entity.medical_problems && entity.medical_problems['forbidden'] ? entity.medical_problems['forbidden'].join(', ') : '',
                            }
                            return array
                        })
                        this.total_items = response.data.total_items
                        this.pagination.rowsNumber = response.data.total_items
                        eventBus.$emit('hideTabs')
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        fetchFromServer(startRow, count, filter, sortBy, descending) {
            this.getItems()

            const data = this.data

            // handle sortBy
            if (sortBy) {
                const sortFn = sortBy === 'desc' ?
                    (descending ?
                        (a, b) => (a.name > b.name ? -1 : a.name < b.name ? 1 : 0) :
                        (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                    ) :
                    (descending ?
                        (a, b) => (parseFloat(b[sortBy]) - parseFloat(a[sortBy])) :
                        (a, b) => (parseFloat(a[sortBy]) - parseFloat(b[sortBy]))
                    )
                data.sort(sortFn)
            }

            return data.slice(startRow, startRow + count)
        },
        getRowsNumberCount() {
            var route = this.get_route + '/table'

            var url = route + '?page=' + this.pagination.page + '&items_per_page=' + this.pagination.rowsPerPage
            if (this.filter) {
                url += '&search=' + this.filter
            }
            if (this.exercise_active === 'active' && this.exercise_inactive === '') {
                url += '&status=' + this.exercise_active
            }
            if (this.exercise_inactive === 'inactive' && this.exercise_active === '') {
                url += '&status=' + this.exercise_inactive
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.total_items = response.data.total_items
                })
                .catch(function (error) {
                    // console.log(error)
                })

        }
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}

.my-sticky-header-column-table {
    max-width: 100%;
}

tr th {
    background: #fff;
}

thead tr:first-child th,
tr:first-child th:first-child {
    background: #fafafa;
}

.q-table tbody tr.expanded {
    td {
        background-color: rgba(99, 194, 222, .25) !important;

        &:first-child {
            background-color: rgba(99, 194, 222, .25) !important;
        }
    }

    td:nth-child(2) {
        font-weight: bold;
        color: var(--q-color-accent);
    }

    &:nth-child(odd) td {
        border-top: 1px solid var(--q-color-info);
    }

    &:nth-child(even) td {
        border-bottom: 1px solid var(--q-color-info);
    }

    &:hover td:before {
        background: transparent;
    }
}

/* [+] Expanded row buttons, mobile styling. */
@media screen and (max-width: 767px) {
    .q-table tbody tr.expanded .q-item {
        width: calc(100vw - 60px);
        flex-direction: column;

        .q-btn {
            width: 100%;
        }
    }

    .row:not(.q-toggle):not(.q-toggle__thumb).q-table__top,
    .row:not(.q-toggle):not(.q-toggle__thumb).q-table__bottom {
        width: 100%;
    }

    .row:not(.q-toggle):not(.q-toggle__thumb).q-table__bottom {
        padding-left: 0;
        padding-right: 0;
    }
}

/* [-] Expanded row buttons, mobile styling. */
</style>
