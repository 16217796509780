<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer" @click.prevent="isHidden = !isHidden">
                    <q-icon :title="translate('Click to show or hide the included items')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{ translate(main_title) }}
                        <q-badge color="primary" floating>{{ items && items.length ? items.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && items && items.length" class="list-group-item-accent-info list-group-item-divider" :class="'list_wrapper-items-' + entity_type + '-' + item_id">
                    <draggable v-model="items" handle=".handle" group="exercisescollections" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <q-item class="list-item pr-2" v-for="(item,i) in items" :key="'included-item-' + i + item.id" :id="item.link_id">
                                <q-item-section side class="pl-0 handle">
                                    <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                                        <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>
                                        <template v-if="item.rounds">
                                            <!-- rounds -->
                                            <strong class="text-blue">{{ item.rounds }}</strong> <span class="mr-1">x</span>
                                        </template>
                                        <template v-if="item.seconds">
                                            <!-- seconds -->
                                            <strong class="text-primary mr-1">{{ secondsToFormattedTime(item.seconds) }}</strong>
                                        </template>
                                        <!-- exercise_collection_name -->
                                        <strong class="text-blue-10">{{ item.name }}</strong>
                                    </q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click="showDeletePanel(item.link_id)" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </transition-group>
                    </draggable>
                    <div v-if="!items">
                        <q-item class="m-0 pt-3">{{ translate('No included items') }}</q-item>
                    </div>
                </b-list-group-item>
                <infinite-loading slot="append" @infinite="getItems" :identifier="'infiniteLoading-' + item_id  + '-' + infItems" />
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate('add_' + entity_type) }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
                    <include-item-form 
                        :placeholder_find_item="placeholder_find_item" 
                        @update_list="updateIncludedItems" 
                        :item_id="item_id" 
                        :post_route="post_route" 
                        :get_route="get_route" 
                        :get_route_to_include="get_route_to_include" 
                        :entity_type="entity_type">
                    </include-item-form>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"
import InfiniteLoading from 'vue-infinite-loading'

import DeleteIncludedItem from './DeleteIncludedItem'
import IncludeTrainingExercisesCollections from './IncludeTrainingExercisesCollections'
import draggable from 'vuedraggable'

export default {
    name: 'AddIncludedTrainingExercisesCollections',
    props: ['post_route', 'get_route_to_include', 'get_route', 'item_id', 'entity_type', 'main_title', 'placeholder_find_item'],
    components: {
        'include-item-form': IncludeTrainingExercisesCollections,
        draggable
    },
    computed: {
        post_url: function () {
            return this.post_route
        },
        get_url: function () {
            return this.get_route + '/' + this.item_id
        },
        dragOptions() {
            return {
                animation: 0,
                group: "exercisescollections",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    data: function () {
        return {
            drag: false,
            isHidden: false,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date() + 1,
        }
    },
    mounted: function () {
        eventBus.$on('update_included_items', () => {
            this.updateIncludedItems()
        })
    },
    methods: {
        secondsToFormattedTime: function (seconds) {
            const formatted_time = new Date(seconds * 1000).toISOString().substr(11, 8)
            return formatted_time
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        updateIncludedItems: function () {
            this.resetMainList()
        },
        getItems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_route + '/' + this.item_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.item.exercises_collections && response.data.item.exercises_collections.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response.data.item.exercises_collections)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteIncludedItem,
                props: {
                    item_id: item_id,
                    entity_type: this.entity_type,
                    post_route: this.post_route + '/link',
                    panel_title: this.translate('delete_included_' + this.entity_type),
                    panel_message: this.translate('deleting_included_' + this.entity_type)
                },
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateIncludedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_all')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var drag_id = event.moved.element.link_id
            var items = this.items
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var drop_id = items[drop_index].link_id

            var url = 'exercises_collections/link/position'

            if (this.entity_type === 'exercises_collections') {
                url = 'trainings/link/position'
            }

            var data = {
                "drag_id": drag_id,
                "drop_id": drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your items have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    padding: 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}
.border-right {
    border-right: 1px solid rgba(0, 0, 0, .26);
}
</style>
