<template>
<div class="animated fadeIn">
    <b-row class="m-0">
        <b-col class="p-0">
            <b-card class="m-0" no-body no-header>
                <div class="card-header bg-white pl-3 pr-3 pt-2 pb-0">
                    <q-item class="full-width m-0 p-0">
                        <q-item-section side class="p-0">
                            <h3 class="text-dark text-h6">{{ main_title }}</h3>
                        </q-item-section>
                    </q-item>
                </div>
                <search-wrapper-card :fullwidth_textinput="true" :active="item_active" :activeStatus="activeStatus" :inactive="item_inactive" :inactiveStatus="inactiveStatus" :form_type="'items-' + entity_type">
                    <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" :id="'filtername-item-' + entity_type" class="search-input" type="text" :placeholder="placeholder_find_item" :dense="true">
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                    <template slot="add_button">
                        <q-item class="p-0">
                            <q-item-section side class="pr-2">
                                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="showAddItemPanel">
                                    <i class="fa fa-plus mr-1"></i> {{ add_button_text }}
                                </q-btn>
                                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="showAddItemPanel" />
                            </q-item-section>
                            <q-item-section side class="pr-0">
                                <import-csv :import_csv_post_url="import_csv_post_url" :panel_title="translate(entity_type + 's')" :entity_type="entity_type" />
                            </q-item-section>
                        </q-item>
                    </template>
                </search-wrapper-card>
            </b-card>
            <div class="list-group" :class="'wrapper_collection_library_item-' + entity_type">
                <draggable v-model="items" handle=".handle" group="items" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <q-item v-for="(item,i) in items" :key="'library-item-' + i + '-' + item.id" :id="item.id" class="list-item full-width">
                            <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                                <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                                    <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                                </q-btn>
                            </q-item-section>
                            <q-item-section class="p-0" side>
                                <div class="image-thumbnail cursor-pointer handle" @click.prevent="showEditItemPanel(item)">
                                    <i class="fa fa-photo fa-2x"></i>
                                </div>
                            </q-item-section>
                            <q-item-section class="full-height align-self-center pt-2 pb-2">
                                <template v-if="item.training_phase_name">
                                    <small class="text-muted">{{ item.training_phase_name }}</small>
                                </template>
                                <template v-if="item.training_level_name || item.training_type_name">
                                    <small class="text-muted">
                                        {{ item.training_level_name }}<template v-if="item.training_level_name && item.training_type_name">, </template>
                                        {{ item.training_type_name }}
                                    </small>
                                </template>
                                <strong class="text-muted">{{ item.name }}</strong>
                            </q-item-section>
                            <!-- [+] Desktop sections. -->
                            <template v-if="!$q.screen.lt.sm">
                                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                                    <!-- Optional Buttons -->
                                    <slot :flat="false" name="buttons" :item_id="item.id"></slot>
                                </q-item-section>
                                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                                    <q-btn round icon="edit" color="primary" @click="showEditItemPanel(item)">
                                        <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                                    <q-btn round icon="delete" color="danger" @click="showDeleteItemPanel(item.id)">
                                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section v-if="patch_toggle_route" side class="full-height align-self-center pr-0 pt-2 pb-2">
                                    <toggle-active :is_active="item.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="item.id" :entity_type="entity_type" />
                                </q-item-section>
                            </template>
                            <!-- [-] Desktop sections. -->
                        </q-item>
                    </transition-group>
                </draggable>
            </div>
            <infinite-loading :identifier="'infiniteLoading-' + entity_type + '-' + infItems" slot="append" @infinite="getItems" />
        </b-col>
    </b-row>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import AddItem from './forms/AddItem'
import EditItem from './forms/EditCollectionItem'
import DeleteItem from './forms/DeleteItem'
import draggable from 'vuedraggable'
import ImportCSV from '../import/ImportCSV'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'CollectionLibraryItem',
    props: [
        'entity_type', 'include_entity_type', 'patch_toggle_route', 'link_post_route', 'get_route_to_include', 
        'get_route', 'post_route', 'panel_title', 'main_title', 'add_button_text', 'add_item_title', 'edit_item_title', 
        'delete_item_title', 'deleting_item_message', 'placeholder_find_item', 'field_unit', 'field_description', 
        'field_exercises_collections', 'field_exercises', 'include_single_choice'],
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'import-csv': ImportCSV,
        'toggle-active': ToggleActive,
        draggable,
    },
    computed: {
        import_csv_post_url: function () {
            return this.get_route + '/import'
        },
        dragOptions() {
            return {
                animation: 0,
                group: "collection",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    mounted: function () {
        eventBus.$on('delete_' + this.entity_type, (id) => {
            this.deleteCollectionItem(id)
        })
        eventBus.$on('update_main_list_' + this.entity_type, () => {
            this.resetMainList()
        })
        eventBus.$on('toggle_active_' + this.entity_type, (item) => {
            this.toggleActiveItem(item)
        })
    },
    data: function () {
        return {
            drag: false,
            items: [],
            searchQuery: '',
            page: 1,
            isTyping: false,
            infItems: +new Date(),
            state: {},
            item_active: 'active',
            item_inactive: '',
        }
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateCollectionLibraryItem: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.items.findIndex((obj => obj.id === item.id))
            // and update the element from the items array.
            //-- name
            this.items[objIndex]['name'] = item.name ? item.name : ''
            //-- training_phase, training_level, training_type [*_name and *_id]
            if (this.include_single_choice && this.include_single_choice.length) {
                this.include_single_choice.map(choice => {
                    this.items[objIndex][choice.field + '_name'] = item[choice.field + '_name'] ? item[choice.field + '_name'] : null
                    this.items[objIndex][choice.field + '_id'] = item[choice.field + '_id'] ? item[choice.field + '_id'] : null
                })
            }
        },
        toggleActiveItem: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.items.findIndex((obj => obj.id === item.id))
                if (this.items[objIndex]) {
                    // And update is_active for the given item in list.
                    this.items[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.item_active === 'active' && this.item_inactive === '') {
                        this.deleteCollectionItem(item.id)
                    }
                    if (item.is_active === 1 && this.item_active === '' && this.item_inactive === 'inactive') {
                        this.deleteCollectionItem(item.id)
                    }
                }
            }
        },
        deleteCollectionItem: function (id) {
            if (id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.items.findIndex((obj => obj.id === id))
                // and remove the element from the items array.
                this.items.splice(objIndex, 1)
            }
        },
        activeStatus: _.debounce(function () {
            if (this.item_active === 'active') {
                this.item_active = ''
            } else {
                this.item_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.item_inactive === 'inactive') {
                this.item_inactive = ''
            } else {
                this.item_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        getItems: function ($state) {
            var url = this.get_route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            if (this.searchQuery) {
                url += '&search=' + this.searchQuery
            }
            if (this.item_active === 'active' && this.item_inactive === '') {
                url += '&status=' + this.item_active
            }
            if (this.item_inactive === 'inactive' && this.item_active === '') {
                url += '&status=' + this.item_inactive
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let items = this.items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.items = items
                        $state.complete()
                    }
                    eventBus.$emit('hideTabs')
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddItemPanel() {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_item_title,
                    post_route: this.post_route,
                    entity_type: this.entity_type,
                    field_unit: this.field_unit,
                    field_description: this.field_description,
                    include_single_choice: this.include_single_choice,
                    field_exercises: this.field_exercises ? this.field_exercises : null,
                    field_exercises_collections: this.field_exercises_collections ? this.field_exercises_collections : null,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditItemPanel(item) {
            const props = {
                item_id: item.id,
                is_active: item.is_active,
                edit_item_title: this.edit_item_title,
                link_post_route: this.link_post_route,
                get_route_to_include: this.get_route_to_include,
                get_route: this.get_route,
                post_route: this.post_route,
                entity_type: this.entity_type,
                field_unit: this.field_unit,
                field_description: this.field_description,
                delete_item_title: this.delete_item_title,
                deleting_item_message: this.deleting_item_message,
                include_entity_type: this.include_entity_type,
                patch_toggle_route: this.patch_toggle_route,
                include_single_choice: this.include_single_choice,
            }
            if (this.include_single_choice && this.include_single_choice.length) {
                this.include_single_choice.map(choice => {
                    props[choice.field + '_name'] = item[choice.field + '_name'] ? item[choice.field + '_name'] : ''
                    props[choice.field + '_id'] = item[choice.field + '_id'] ? item[choice.field + '_id'] : 0
                })
            }
            const panelInstance = this.$showPanel({
                component: EditItem,
                props: props
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateCollectionLibraryItem(result)
                    }
                })
        },
        showDeleteItemPanel(id) {
            const panelInstance = this.$showPanel({
                component: DeleteItem,
                props: {
                    item_id: id,
                    delete_item_title: this.delete_item_title,
                    deleting_item_message: this.deleting_item_message,
                    post_route: this.post_route,
                    entity_type: this.entity_type
                }
            })
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var drag_id = event.moved.element.id
            var items = this.items
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var drop_id = items[drop_index].id

            var data = {
                "exercises_collection_drag_id": drag_id,
                "exercises_collection_drop_id": drop_id
            }
            var url = 'exercises_collections/position'

            if (this.entity_type === 'training') {
                data = {
                    "training_drag_id": drag_id,
                    "training_drop_id": drop_id
                }
                url = 'trainings/position'
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your items have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}
</style>
