<template>
<div>
    <div v-if="field_reference">
        <b-list-group-item class="list-group-item-accent-danger m-0 mb-3 pl-1"><q-select class="m-0" dense outlined v-model="selected_reference" :options="reference_options" :label="translate('select_reference')" /></b-list-group-item>
    </div>
    <template v-if="(field_reference && selected_reference) || !field_reference">
        <div v-if="!items || items.length == 0">
            <div class="text-muted">{{ translate('no_results') }}</div>
        </div>
        <div v-else>
            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
        </div>
        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
            <template v-slot:append>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showIncludeItemPanel(item.id, entity_type)" flat dense slot="add_button" color="dark" no-caps :label="translate('new_' + entity_type)" icon="add" />
            </template>
        </q-input>
        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + item_id">
            <q-item tag="label" class="list-item full-width" clickable ripple v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
                <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-btn icon="add" @click.prevent="registerItem(item)" dense flat color="primary" />
                </q-item-section>
            </q-item>
        </div>
        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import AddItem from './AddItem'

export default {
    name: 'IncludeItem',
    props: ['post_route', 'get_route', 'item_id', 'placeholder_find_item', 'entity_type', 'field_reference'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading,
    },
    computed: {
        reference_options: function () {
            var options = [{
                    value: 'recommended',
                    label: this.translate('recommended')
                },
                {
                    value: 'not_recommended',
                    label: this.translate('not_recommended')
                },
                {
                    value: 'forbidden',
                    label: this.translate('forbidden')
                }
            ]
            
            return options
        },
    },
    data: function () {
        return {
            selected_reference: null,
            items: [],
            page: 1,
            state: {},
            infItems: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null
            }
        }
    },
    mounted: function () {
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
    },
    methods: {
        showIncludeItemPanel: function (id, entity_type) {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    item_id: id,
                    get_route: baseUrl + entity_type + 's',
                    post_route: baseUrl + entity_type + 's',
                    add_item_title: this.translate('add_' + this.entity_type),
                    entity_type: entity_type,
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        post_data: function (item) {
            var data = {
                "exercise_id": this.item_id,
                "link_id": item.id,
                "link_type": this.entity_type,
            }
            if (this.selected_reference) {
                data['reference'] = this.selected_reference.value
            }
            return data
        },
        getItems: function ($state) {
            var entity_type = this.entity_type ? this.entity_type + 's' : ''
            var route = this.get_route  + '/' + this.item_id + '/non_included/' + entity_type
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerItem: function (item) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var data = this.post_data(item)
            var url = this.post_route
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        // Visually remove the added item from the list of available items.
                        this.items.splice(this.items.indexOf(item), 1)
                        this.$emit('update_list')
                        eventBus.$emit('update_included_items', {id: this.item_id, route: this.entity_type + 's'})
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    if (error != undefined) {
                        var msg = error.response.data.msg
                        this.msg = msg
                        this.$toasted.error(msg, {
                            duration: 3000
                        })
                    }

                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 72.5vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
body .search-input {
    width: calc(100% + 30px);
}
</style>
